import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate, getFormattedTime } from "../../helpers/utils"

import './FundraisingEvent.css';
// import { Link } from 'react-router-dom';

/**
 * The NewSection component displays (for the time being) a single news item,
 * with associated text and image.
 *
 * props:
 *  title - Title of the news
 * image - Image url for news image
 * description - text text description of news item
 */
const FundraisingEvent = ({ event, isCurrentEvent, isSoldOut }) => {

  return (
      <div className='new-container'>
        <img className='news-image' src={event.posterImage} alt='Big quiz poster' />
        <div className='news-text-container'>
          <h3 className='quiz-title'>{event.name}</h3>
          <p>{event.description}</p>
          <br />
          <div className='event-info'>
            <p><span>Date:</span>{`${getFormattedDate(event.eventDate)} - ${getFormattedTime(event.eventDate)}`}</p>
          </div>
          {event.location !== '' && (
            <div className='event-info'>
              <p><span>Location:</span>{event.location}</p>
            </div>
          )}
          
          {event.ticketCost !== '' && (
            <div className='event-info'>
              <p><span>Ticket cost:</span>{`£${event.ticketCost}`}</p>
            </div>
          )}
         
          {!isSoldOut ? (
            <div className='book-button'>
              {isCurrentEvent ? (
                // <Link className='button full-dark' to='/book-event' state={{event: event}}>
                //   Book now
                // </Link>
                <a
                className='button full-dark'
                // href={event["donate"] ?? "https://www.stewardship.org.uk/partners/20406643"}
                href={"https://2025tcslondonmarathon.enthuse.com/acttanzania/profile"}
                target="_blank"
                rel="noreferrer"
                data-testid='donate-button'
              >
                Donate
              </a>
              ) : (
                <></>
                // <Link className='button full-dark' to='/fundraising-event' state={{event: event}}>
                //   More info
                // </Link>
              )}
            </div>
          ) : (
            <div className='book-button'>
              <p>Sold out</p>  
            </div>
          )}
        </div>
      </div>
  );
}

FundraisingEvent.defaultProps = {
  isCurrentEvent: true,
  isSoldOut: false
}

FundraisingEvent.propTypes = {
  description: PropTypes.string.isRequired
}

export default FundraisingEvent;
